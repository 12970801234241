import React from "react";
import { navigate } from "gatsby";

import Button from "../../button/Button";

import { getSlug } from "../../../../../../common/helpers";

const HeroVariantOneButton = (props) => {
  const children = [];

  children.push(
    <Button
      theme={props.color_styles === "Dark" ? "transparentDark" : "transparent"}
      key={getSlug("Shop Now")}
      label={props.cta_1_title}
      onClick={(e) => {
        navigate(`${props.cta_1_href}`);
      }}
    />
  );

  return children;
};

export default HeroVariantOneButton;
