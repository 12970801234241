// extracted by mini-css-extract-plugin
export var alignLeft = "hero-module--alignLeft--4a8d7";
export var alignLeftDescrition = "hero-module--alignLeftDescrition--c56e9";
export var changeMaxWidth = "hero-module--changeMaxWidth--2634c";
export var hero = "hero-module--hero--61ff1";
export var heroBtnContainer = "hero-module--heroBtnContainer--98f07";
export var heroContainer = "hero-module--heroContainer--e4a2c";
export var heroDescription = "hero-module--heroDescription--b80da";
export var heroHeader = "hero-module--heroHeader--5a5b5";
export var heroHeaderBig = "hero-module--heroHeaderBig--7ec10";
export var heroTall = "hero-module--heroTall--5d5be";
export var heroVideo = "hero-module--heroVideo--72162";
export var short = "hero-module--short--773fd";
export var show_on_mobile = "hero-module--show_on_mobile--ada9c";
export var tgs_hero = "hero-module--tgs_hero--8016c";
export var tgs_hero_inner = "hero-module--tgs_hero_inner--2db0b";