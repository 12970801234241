import React from "react";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import queryString from "query-string";
import * as styles from "../styles/posts.module.css";
import Hero from "../components/hero/Hero";
import { getHtmlParseOptions, GhubLink } from "../../../../common/common";

import FourZeroFour from "../views/404";

export default function Post(props) {
  const state = useSelector((state) => state);
  // const layoutData = state.hydrate.layoutData;
  const postData = state.hydrate.postData;
  var d =
    postData.post &&
    postData.post.published_on &&
    typeof postData.post.published_on === "string"
      ? new Date(postData.post.published_on)
      : null;
  const date = d
    ? d.toLocaleString("default", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
    : null;

  const parseOptions = getHtmlParseOptions();
  const parsed = queryString.parse(props.location.search);

  if (postData && postData.url) {
    return (
      <>
        <Hero
          postHog={props.posthog}
          subheading=""
          heading="In the News"
          image_path="https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/blt3903a8cfb6d82fa3/628551f30457495358472abe/cannabis_leaves.jpg"
        />
        <div className={styles.post_container}>
          <h1 className={styles.post_header}>{postData.title}</h1>
          {/*}  <h2>{`By: ${postData.post.legacy_author}`}</h2> */}
          <h3 className={styles.post_date}>{` ${date}`}</h3>
          <div className={styles.post_body}>
            {postData.post.body
              ? parse(postData.post.body, parseOptions)
              : null}
          </div>
          <div className={styles.post_breadcrumb}>
            <hr />
            <GhubLink
              className={styles.post_breadcrumb_link}
              label={`<< Back to Press Releases`}
              destination={
                parsed.page && 1 !== parseInt(parsed.page)
                  ? `/news/press-release/page-${parsed.page}`
                  : `/news/press-release`
              }
            />
          </div>
        </div>
      </>
    );
  } else {
    // if there isn't a page in the CMS, 404 is correct
    // if you want a static page outside of the CMS it must be a file
    // said file should be in the router
    return <FourZeroFour />;
  }
}
