import React from "react";
import { navigate } from "gatsby";

import Button from "../../button/Button";

import { getSlug } from "../../../../../../common/helpers";

const HeroVariantTwoButton = (props) => {
  const children = [];

  children.push(
    <Button
      theme={props.color_styles === "Dark" ? "transparentDark" : "transparent"}
      key={getSlug(props.cta_1_title)}
      label={props.cta_1_title}
      onClick={(e) => {
        navigate(`${props.cta_1_href}`);
      }}
    />
  );

  children.push(
    <Button
      theme={props.color_styles === "Dark" ? "transparentDark" : "transparent"}
      key={getSlug(props.cta_2_title)}
      label={props.cta_2_title}
      onClick={(e) => {
        navigate(`${props.cta_2_href}`);
      }}
    />
  );

  return children;
};

export default HeroVariantTwoButton;
