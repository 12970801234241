import React, {useState} from "react";
import { useSelector } from "react-redux";

import HeroBlock from "../../../../../common/blocks/hero/Hero";

import HeroVariantOneButton from "./variants/one_button";
import HeroVariantTwoButton from "./variants/two_button";

const Hero = (props) => {
  // console.log("Hero component props:", props);

  // we can use redux state in components but NOT the common stuff; props only
  const state = useSelector((state) => state);

  const [heroShow, setHeroShow] = useState(true); // always on

  let children;
  switch (props.variant) {
    case "one_button":
      children = HeroVariantOneButton(props);
      break;
    case "two_button":
      children = HeroVariantTwoButton(props);
      break;
    default:
      children = null;
      break;
  }

  const heroBlock = (
    // pass state in camelCase to help differentiate between props and state
    <HeroBlock heroShow={heroShow} {...props}>
      {children}
    </HeroBlock>
  );

  return heroBlock;
};

Hero.displayName = "Hero";

export default Hero;
