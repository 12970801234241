import React from "react";
import PropTypes from "prop-types";
import Button from "../../elements/button/Button";
// import Captions from "file-loader!../../assets/captions.vtt";
import * as HeroStyles from "./hero.module.css";
import { navigate } from "gatsby";
import { getContentstackEnvironment } from "../../helpers";
import { posthogEvents } from "@col-care/common/utilities/constants";
import { capturePosthogLink } from "@col-care/common/utilities/index";

const HeroBlock = (props) => {
  // console.log("Hero block props:", props);
  // console.log("Hero styles", HeroStyles);

  const heroData = props;
  const heroStyles = HeroStyles.default || HeroStyles;
  if (!props.heroShow || !heroData) {
    return null;
  }

  const heroHeader = heroData.heading;
  const image = heroData.image_path;
  const backgroundColor = "";
  const video = props.video;
  const video_poster = props.video_poster;
  const posthog = props?.pageContext?.posthog || props?.posthog;

  return (
    <>
      {props.color_styles === "TGS Beige" ? (
        <div className={heroStyles.tgs_hero}>
          <div className={heroStyles.tgs_hero_inner}>
            {props.set_aria_role_to_main ? (
              <h1 role="main"> {heroHeader}</h1>
            ) : (
              <h3> {heroHeader}</h3>
            )}
            {props.subheading && <p>{props.subheading}</p>}
            {props.cta_1_href && (
              <div className={heroStyles.heroBtnContainer}>
                {props.cta_1_href && (
                  <Button
                    theme="tgsButton"
                    label={props.cta_1_title}
                    onClick={(e) => {
                      navigate(`${props.cta_1_href}`);
                      capturePosthogLink({
                        eventName: posthogEvents.HERO_BLOCK_ENGAGEMENT,
                        title: props.cta_1_title,
                        to: props.cta_1_href,
                        posthog,
                      });
                    }}
                  />
                )}
                {props.cta_2_href && (
                  <Button
                    theme="tgsButton"
                    label={props.cta_2_title}
                    onClick={(e) => {
                      navigate(`${props.cta_2_href}`);
                      capturePosthogLink({
                        eventName: posthogEvents.HERO_BLOCK_ENGAGEMENT,
                        title: props.cta_2_title,
                        to: props.cta_2_href,
                        posthog,
                      });
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          <header
            aria-label={heroHeader}
            id={`${
              props.display_on_mobile_only_hero_banner === "Yes"
                ? `${heroStyles.show_on_mobile}`
                : ""
            }`}
            className={`${
              props.layout_styles === "Spaced Out"
                ? `${heroStyles.heroTall}`
                : `${heroStyles.hero}`
            } ${props.height === "Short" ? `${heroStyles.short}` : ""}`}
            style={
              image
                ? { backgroundImage: `url(${image})` }
                : { backgroundColor: `${backgroundColor}` }
            }
          >
            {video && (
              <video
                className={heroStyles.heroVideo}
                loop
                autoPlay
                muted
                playsInline
                poster={video_poster?.url}
                aria-hidden="true"
              >
                <source src={video.url} type="video/mp4" />
                {/* <track style={ { display: 'none' } } default src={Captions} kind="captions" srclang="en" label="english_captions" /> */}
              </video>
            )}
            <div
              role={props.set_aria_role_to_main ? "main" : "banner"}
              className={`${
                props.alignment === "Left"
                  ? `${heroStyles.heroContainer} ${heroStyles.changeMaxWidth} `
                  : `${heroStyles.heroContainer}`
              }`}
            >
              {getHeroHeading(props)}
              <div className={heroStyles.heroBtnContainer}>
                {props.cta_1_href && !props.display_on_mobile_only_hero_banner && (
                  <Button
                    theme={
                      props.color_styles === "Dark"
                        ? "transparentDark"
                        : getContentstackEnvironment() === "comms"
                        ? "transparent"
                        : "transparentCC"
                    }
                    label={props.cta_1_title}
                    onClick={(e) => {
                      navigate(`${props.cta_1_href}`);
                      capturePosthogLink({
                        eventName: posthogEvents.HERO_BLOCK_ENGAGEMENT,
                        title: props.cta_1_title,
                        to: props.cta_1_href,
                        posthog,
                      });
                    }}
                  />
                )}
                {props.display_on_mobile_only_hero_banner === "Yes" && (
                  <Button
                    theme="transparentBist"
                    label={props.cta_1_title}
                    onClick={(e) => {
                      navigate(`${props.cta_1_href}`);
                      capturePosthogLink({
                        eventName: posthogEvents.HERO_BLOCK_ENGAGEMENT,
                        title: props.cta_1_title,
                        to: props.cta_1_href,
                        posthog,
                      });
                    }}
                  />
                )}
                {props.cta_2_href && (
                  <Button
                    theme={
                      props.color_styles === "Dark"
                        ? "transparentDark"
                        : "transparent"
                    }
                    label={props.cta_2_title}
                    onClick={(e) => {
                      navigate(`${props.cta_2_href}`);
                      capturePosthogLink({
                        eventName: posthogEvents.HERO_BLOCK_ENGAGEMENT,
                        title: props.cta_2_title,
                        to: props.cta_2_href,
                        posthog,
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </header>
        </>
      )}
    </>
  );
};

export default HeroBlock;

const getHeroHeading = (props) => {
  const heroStyles = HeroStyles.default || HeroStyles;
  if (
    props.layout_styles === "Normal" ||
    props.layout_styles === "Spaced Out"
  ) {
    return (
      <>
        <h2
          role="heading"
          aria-level="2"
          className={`${
            props.alignment === "Left"
              ? `${heroStyles.heroHeader} ${heroStyles.alignLeft}`
              : `${heroStyles.heroHeader}`
          }`}
          style={
            props.color_styles === "Dark"
              ? { color: "#202245" }
              : { color: "#fff" }
          }
        >
          {props.heading}
        </h2>
        {props.subheading && (
          <h3 className={heroStyles.heroDescription}>{props.subheading}</h3>
        )}
      </>
    );
  } else {
    return (
      <h1 role="heading" aria-level="1" className={heroStyles.heroHeaderBig}>
        {props.heading}
      </h1>
    );
  }
};

HeroBlock.defaultProps = {
  display_on_mobile_only_hero_banner: false,
};

HeroBlock.propTypes = {
  layout_styles: PropTypes.oneOf([
    "Normal",
    "Spaced Out",
    "Bold Text",
    "TGS Slim",
  ]),
  color_styles: PropTypes.oneOf(["Dark", "Light", "TGS Beige"]),
  alignment: PropTypes.oneOf(["Left", "Center"]),
  display_on_mobile_only_hero_banner: PropTypes.bool,
  heading: PropTypes.string,
  subheading: PropTypes.string,
};
